import { IWsService } from "./IWsService";
import { Broadcast } from "../broadcast/broadcast";
import { WebsocketEvents } from "../broadcast/WebSocketEvents";
import { AppInstanceId } from "../../utils/AppInstanceId";
import { CurrentUser } from "../../core/context/user/currentUser/CurrentUser";
import { DeviceOs } from "../../utils/DeviceOs";

export class WsService implements IWsService {
    private webSocket: WebSocket;
    private _url: string;
    private _isConnected: boolean = false;
    private type = "user";

    constructor(url: string = "") {
        this.setUrl(url);
    }

    setUrl(url: string) {
        this._url = url;
    }

    start() {
        this.open();
    }

    stop() {
        if (this.webSocket) this.webSocket.close();
    }

    private connect = () => {
        if (!this._isConnected) {
            this.open();
        }
    };

    private open() {
        const url = this.createUrl(this._url);
        console.log("WS opened");
        try {
            this.webSocket = new WebSocket(url);
            this.webSocket.onopen = this.onOpen;
            this.webSocket.onmessage = this.onMessage;
            this.webSocket.onerror = this.onError;
            this.webSocket.onclose = this.onClose;
            this._isConnected = true;
        } catch (e) {
            console.error("websocket error", e);
        }
    }

    private onOpen = () => {
        Broadcast.trig(WebsocketEvents.onSocketOpen, {});
    };

    private onMessage = (ev: MessageEvent) => {
        Broadcast.trig(WebsocketEvents.onMessage, ev);
    };

    private onError = (ev: Event) => {
        console.log("ws error", ev);
        Broadcast.trig(WebsocketEvents.onSocketError, ev);
    };

    private onClose = (ev: CloseEvent) => {
        console.log("Closed", ev);
        this._isConnected = false;
        Broadcast.trig(WebsocketEvents.onSocketClose, ev);
        this.stop();
        this.connect();
    };

    private createUrl(url: string): string {
        return `${url}?uniqId=${
            CurrentUser.getCurrentUser()?.id
        }&accessToken=${CurrentUser.getToken()}&type=${
            this.type
        }&appInstanceId=${AppInstanceId}&device=${DeviceOs.os()}`;
    }
}
