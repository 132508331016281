import { useLocation, useNavigate } from "react-router-dom";
import { PathUtils } from "@utils/path/PathUtils";
import { CurrentUser } from "../../../core/context/user/currentUser/CurrentUser";
import { useCallback, useEffect } from "react";
import { Broadcast } from "../../../infrastructure/broadcast/broadcast";
import { AuthEvents } from "../../../core/context/auth/AuthEvents";
import { ISuccessLoginParams } from "../../../core/context/login/dto/ISuccessLoginParams";
import { Notify } from "@utils/notify/Notify";
import { useInject } from "../../../core/AppDiContainer";
import { IAuthService } from "../../../core/context/auth/service/IAuthService";
import { ApplicationDiType } from "@ui/diType";
import { IUserService } from "../../../core/context/user/service/IUserService";
import { ConfigAccess } from "@utils/ConfigAccess";

export const SuccessLoginScreen = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const onSuccess = useCallback(async (code: string) => {
        try {
            const authService = useInject<IAuthService>(
                ApplicationDiType.IAuthService,
            );
            const userService = useInject<IUserService>(
                ApplicationDiType.IUserService,
            );
            const token = await authService.getToken(code);
            await ConfigAccess.init();
            const user = await userService.getByToken(token.access_token);
            CurrentUser.setUser(user);
            Broadcast.trig(AuthEvents.login, null);
            navigate("/", { replace: true });
        } catch (e) {
            console.error(e);
            Notify.error({ message: e as string });
        }
    }, []);

    useEffect(() => {
        const params = PathUtils.getQueryStringParams(
            location.search.split("?")[1],
        ) as ISuccessLoginParams;
        params.code && onSuccess(params.code);
    }, []);

    return null;
};
