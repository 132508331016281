import { configuration, dto, hooks } from "@mrs/platform-configuration-client";
import { Form } from "../form/model/Form";
import { View } from "../view/model/View";
import { MenuItem } from "../menuItem/model/MenuItem";
import { MenuHook } from "../menuItem/model/MenuHook";
import { Broadcast } from "../../../infrastructure/broadcast/broadcast";
import { ConfigurationEvents } from "./ConfigurationEvents";
import { ReportTemplate } from "../reportTemplate/model/ReportTemplate";
import { IAppSettings } from "@utils/IAppSettings";
import { Nullable, TCallback, ViewType } from "@mrs/webclient-shared-ui-lib";
import { ConfigAccess } from "@utils/ConfigAccess";
import { HookCallbackMap } from "../../types/ConfigurationTypes";
import { CurrentUser } from "../user/currentUser/CurrentUser";

import Configuration = configuration.SingleTenantConfiguration;
import Hooks = hooks.HooksConfiguration;
import HOOK_TYPE = dto.hook.HOOK_TYPE;
import ViewDTO = dto.view.ViewDTO;
import Settings = configuration.Settings;

class ConfigurationAccessClass {
    _configuration: Configuration;
    _hooks: Nullable<Hooks> = null;
    _views: dto.view.ViewDTO[]; // удалить после обновления кастомных компонентов
    _tempFormHooks: Map<string, HookCallbackMap>;
    _tempViewHooks: Map<string, HookCallbackMap<string>>;
    _tempModules: dto.ComponentDTO[];

    async init(url: string): Promise<boolean> {
        const locally = process.env.LOCAL_CONFIGURATION === "true";
        const settings = await new Settings(
            CurrentUser.getToken(),
            // TODO: посмотреть еще
            true,
            locally,
            undefined,
        ).getSettingsAsync(url);
        this._configuration = new Configuration(settings);
        this._hooks = new Hooks(settings);

        await this._configuration.initAsync(url);
        if (this._hooks) {
            try {
                await this._hooks.initAsync(url);
            } catch (e) {
                console.error("Error initializing hooks:", e);
                this._hooks = null;
                console.log("Hook module not found");
            }
        } else {
            console.log("Hooks are not initialized.");
        }
        this._views = await this._configuration.view.getAllAsync(null);
        this._tempFormHooks = await this.getAllFormHooks();
        this._tempViewHooks = await this.getAllViewHooks();
        this._tempModules = await this._configuration.library.getAllAsync(url);
        Broadcast.trig(ConfigurationEvents.onInitialized, null);
        return true;
    }

    async clean() {
        await this._configuration.cleanAsync(null);
    }

    async getForm(name: string): Promise<Form | undefined> {
        const dto = JSON.parse(
            await this._configuration.form.getAsJsonAsync(name, null),
        );
        if (!dto) return;
        return new Form(dto);
    }

    async getView(name: string): Promise<View | undefined> {
        const dto = await this._configuration.view.getAsync(name);
        if (!dto) return;
        return new View(dto);
    }

    getViewSync(name: string): View | undefined {
        const dto = this.findItem(
            this._views,
            (view: ViewDTO) => view.name == name,
        );
        if (!dto) return;
        return new View(dto);
    }

    async getFormComponents(): Promise<dto.ComponentDTO[]> {
        try {
            return this._configuration.library.getModulesByTypeAsync(
                "form",
                ConfigAccess.configUrl,
            );
        } catch (e) {
            return [];
        }
    }

    getViewComponentByTypeSync(
        viewType: ViewType,
    ): Nullable<dto.ComponentViewDTO> {
        console.log(`Get view component by type sync ${viewType}`);
        const modules = this._tempModules as dto.ComponentViewDTO[];

        return this.findItem(
            modules,
            (item: dto.ComponentViewDTO) => item.viewType === viewType,
        );
    }

    async getViewComponentByType(
        viewType: ViewType,
    ): Promise<dto.ComponentViewDTO | null | undefined> {
        console.log("Get view component by type async");
        try {
            return this._configuration.library.getModuleByViewTypeAsync(
                viewType,
                ConfigAccess.configUrl,
            );
        } catch (e) {
            return null;
        }
    }

    async getMenuItems(): Promise<MenuItem[]> {
        const dto = await this._configuration.menu.getItemsAsync(null);
        return this.toEntities(MenuItem, dto);
    }

    async getMenuHook(): Promise<TCallback | null> {
        if (!this._hooks) {
            console.error("Hooks module not found");
        }

        if (!!this._hooks && !!this._hooks?.getMenuHook) {
            return this._hooks.getMenuHook();
        }

        const hook = await this._configuration.menu.getHookAsync();
        if (!hook) return null;

        return new MenuHook(hook).hook;
    }

    async findHooksByFormName(formName: string) {
        if (!!this._hooks) {
            return this._hooks.getFormHooks(formName);
        }

        const allHooks = await this._configuration.hook.getFormHooksAsync(
            formName,
            null,
            null,
        );

        const formHooks: HookCallbackMap = new Map();
        allHooks.forEach((hook) => {
            const func = this.createFunctionFromHook(hook);
            formHooks.set(hook.hookType, func);
        });

        return formHooks;
    }

    async getAllFormHooks() {
        if (!!this._hooks) {
            return this._hooks.getAllFormHooks();
        }

        const allHooks: Map<string, HookCallbackMap> = new Map();
        const legacyHooks = await this._configuration.hook.getFormHooksAsync(
            null,
            null,
            null,
        );

        legacyHooks.forEach((hook) => {
            let formHooks: HookCallbackMap = new Map();
            if (allHooks.has(hook.form)) {
                formHooks = allHooks.get(hook.form)!!;
            }

            const func = this.createFunctionFromHook(hook);
            formHooks.set(hook.hookType, func);
            allHooks.set(hook.form, formHooks);
        });

        return allHooks;
    }

    async getCanCreateHook(
        formName?: Nullable<string>,
    ): Promise<TCallback | undefined> {
        if (!formName) {
            return undefined;
        }

        const hooksForForm = await this.findHooksByFormName(formName);
        const result = hooksForForm.get(HOOK_TYPE.CAN_CREATE);

        return !!result ? result : undefined;
    }

    getCanCreateHooksSync(formName?: Nullable<string>) {
        if (!!formName) {
            const hooksForForm = this._tempFormHooks.get(formName);
            if (!!hooksForForm) {
                return hooksForForm.get(HOOK_TYPE.CAN_CREATE);
            }
        }
        return null;
    }

    async getAllViewHooks() {
        if (!!this._hooks) {
            return this._hooks.getAllViewHooks();
        }

        const allHooks: Map<string, HookCallbackMap<string>> = new Map();
        const legacyHooks = await this._configuration.hook.getViewHooksAsync(
            null,
            null,
            null,
        );

        legacyHooks.forEach((hook) => {
            let viewHooks: HookCallbackMap<string> = new Map();
            if (allHooks.has(hook.view)) {
                viewHooks = allHooks.get(hook.view)!!;
            }

            const func = this.createFunctionFromHook(hook);
            viewHooks.set(hook.name, func);
            allHooks.set(hook.view, viewHooks);
        });

        return allHooks;
    }

    getViewHookByName = (
        viewName: string,
        name: string,
    ): TCallback | undefined => {
        if (!!this._hooks) {
            return this._hooks.getViewHook(viewName, name);
        }

        const hooksForView = this._tempViewHooks.get(viewName);
        return !!hooksForView ? hooksForView.get(name) : undefined;
    };

    async getReportTemplates(location: string) {
        const dto = await this._configuration.doctemplate.getAllAsync(
            location,
            null,
        );
        return this.toEntities(ReportTemplate, dto);
    }

    async getConfigurationSettings(): Promise<IAppSettings> {
        return (await this._configuration.manifest.getAppAsync(
            null,
        )) as IAppSettings;
    }

    private createFunctionFromHook(hook: dto.hook.Hook) {
        return new Function(`return (${hook.function})`)();
    }

    private findItem<T>(array: T[], predicate: any): Nullable<T> {
        const result = array.find(predicate);
        return !!result ? result : null;
    }

    private toEntities<TEntity, TDto>(
        domainClass: new (dto: TDto) => TEntity,
        rawObjects: TDto[],
    ) {
        return rawObjects.map((raw) => new domainClass(raw));
    }
}

export const ConfigurationAccess = new ConfigurationAccessClass();
