import { IRestService } from "../../../../infrastructure/http/IRestService";
import { IConfigService } from "./IConfigService";
import { injectable } from "inversify";

@injectable()
export class ConfigService implements IConfigService {
    private readonly restService: IRestService;

    constructor(restService: IRestService) {
        this.restService = restService;
    }

    async getConfig(url: string) {
        const prefix = url.startsWith("http") ? "" : "https://";
        return this.restService.get(`${prefix}${url}/settings/`).asPromise();
    }
}
