import head from "lodash-es/head";
import uniq from "lodash-es/uniq";
import { appStorage } from "../infrastructure/storage/AppStorage";

const OPEN_DIALOG_IDS_KEY = "openDialogIds";

class DialogIdManagerClass {
    get openDialogIds(): string[] {
        const item = appStorage.getItem(OPEN_DIALOG_IDS_KEY);
        return !!item ? JSON.parse(item) : [];
    }

    getFirstOpenDialogId(): string | undefined {
        return head(this.openDialogIds);
    }

    add(id: string) {
        const ids = [id, ...this.openDialogIds];
        this.setOpenDialogIds(uniq(ids));
    }

    remove(id?: string) {
        const ids = this.openDialogIds.filter((value) => value !== id);
        this.setOpenDialogIds(ids);
    }

    clear() {
        this.setOpenDialogIds([]);
    }

    private setOpenDialogIds(ids: string[]) {
        appStorage.setItem(OPEN_DIALOG_IDS_KEY, JSON.stringify(ids));
    }
}

export const DialogIdManager = new DialogIdManagerClass();
