import { CRUDRepository } from "../../../../sharedKernel/repository/impl/CRUDRepository";
import { IFormInstanceRepository } from "../IFormInstanceRepository";
import { IApiService } from "../../../../sharedKernel/apiService/IApiService";
import { FormInstance } from "../../model/FormInstance";
import { IFormInstanceCreateDTO } from "../../dto/IFormInstanceCreateDTO";
import { IFormInstanceDTO } from "../../dto/IFormInstanceDTO";
import { IUserDTO, ObjectId } from "@mrs/webclient-shared-ui-lib";
import { CurrentUser } from "../../../user/currentUser/CurrentUser";
import { ClientException } from "../../../../sharedKernel/error/ClientException";
import cloneDeep from "lodash-es/cloneDeep";
import isEqual from "lodash-es/isEqual";
import set from "lodash-es/set";
import { EntityMapper } from "../../../../sharedKernel/mapper/EntityMapper";
import { Changes } from "../../../../sharedKernel/types/Changes";
import { RequestUtils } from "@utils/request/RequestUtils";
import { Broadcast } from "../../../../../infrastructure/broadcast/broadcast";
import { ConfigurationAccess } from "../../../configuration/ConfigurationAccess";
import { ConfigurationEvents } from "../../../configuration/ConfigurationEvents";
import { CurrentUserEvents } from "../../../user/CurrentUserEvents";
import { HookCallbackMap } from "../../../../types/ConfigurationTypes";

export class FormInstanceRepository
    extends CRUDRepository<
        FormInstance,
        IFormInstanceDTO,
        IFormInstanceCreateDTO
    >
    implements IFormInstanceRepository {
    private _hooks: Map<string, HookCallbackMap> = new Map();
    private _user: IUserDTO | null = null;

    constructor(
        service: IApiService<IFormInstanceDTO, IFormInstanceCreateDTO>,
    ) {
        super(service);
        Broadcast.on(ConfigurationEvents.onInitialized, this.init, this);
        Broadcast.on(CurrentUserEvents.onUpdated, this.requestUser, this);
    }

    async createFormInstance(entity: FormInstance): Promise<FormInstance> {
        entity.canSave();
        const dto = entity.onSave();
        const newItem: IFormInstanceDTO = await this.apiService.createItem(dto);
        return this.toEntity(newItem);
    }

    async changeFormInstance(
        id: ObjectId,
        changes: object,
    ): Promise<FormInstance> {
        const entity = await this.getById(id);
        if (!entity) {
            throw new ClientException(
                "formInstance change error: entity not found",
            );
        }

        const changedEntity = cloneDeep(entity);

        if (changedEntity.canChange(changes)) {
            const changedEntityDto = changedEntity.toDTO();
            Object.entries(changes).forEach(([key, value]) => {
                set(changedEntityDto, key, value);
            });

            changedEntity.fromDTO(changedEntityDto);
            changedEntity.onChange();

            return this.updateFormInstance(entity, changedEntity);
        }

        return changedEntity;
    }

    async updateFormInstance(
        entity: FormInstance,
        changes: Changes<FormInstance>,
    ): Promise<FormInstance> {
        const changedEntity = cloneDeep(entity);
        EntityMapper.updateFields(changedEntity, changes);
        if (!this._user) {
            throw new ClientException(
                "formInstance update error: current user not found",
            );
        }
        changedEntity.canSave();
        const changedDTO = changedEntity.onSave();
        const changesDTO = changes.onSave ? changes.onSave() : {};
        const entityDto = entity.toDTO();
        if (!isEqual(changedDTO, entityDto)) {
            const patch = RequestUtils.createPatch(entityDto, changesDTO);
            await this.apiService.patchItem([entity.id], patch);
        }

        return changedEntity;
    }

    toEntities(data: IFormInstanceDTO[]): FormInstance[] {
        return data.map((item) => this.toEntity(item));
    }

    toEntity(data: IFormInstanceDTO): FormInstance {
        if (!this._user) {
            throw new ClientException(
                "formInstance toEntity error: current user not found",
            );
        }
        const hooks: HookCallbackMap = this._hooks.get(data.formId)!!;
        return new FormInstance(data, cloneDeep(this._user), hooks);
    }

    createEntity(
        dto: Partial<IFormInstanceDTO>,
        initData: object | null,
    ): FormInstance {
        if (!this._user) {
            throw new ClientException(
                "formInstance createEntity error: current user not found",
            );
        }
        const formInstance = this.toEntity(dto as IFormInstanceDTO);
        formInstance.onCreate(initData);
        return formInstance;
    }

    private requestUser(): void {
        this._user = CurrentUser.getDtoWithParsedToken();
    }

    private async requestHooks(): Promise<void> {
        this._hooks = await ConfigurationAccess.getAllFormHooks();
    }

    private init = async () => {
        this.requestUser();
        await this.requestHooks();
    };
}
