import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { DialogViewManagerPresenter } from "./presenter/DialogViewManagerPresenter";
import { useTranslation } from "react-i18next";
import {
    AnalyticsEventAction,
    AnalyticsEventComponent,
    AnalyticsEventLocation,
    AnalyticsEventType,
    IActionButtonProps,
    IAnalyticsEvent,
    TVoidCallback,
    UniversalDialog,
} from "@mrs/webclient-shared-ui-lib";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { ViewSearch } from "@ui/components/shared/ViewSearch/ViewSearch";
import { AnalyticsService } from "@lib/analitics/AnalyticsService";

interface IDialogViewManagerProps {
    isOpen: boolean;
    multiple?: boolean;
    viewId: string;
    title: string;
    values: any[];
    maxSelectedCount?: number;
    variables: object;
    onClose: () => void;
    onApply: TVoidCallback<any[]>;
}

export const DialogViewManager = observer((props: IDialogViewManagerProps) => {
    const {
        viewId,
        multiple,
        isOpen,
        title,
        values,
        onClose,
        variables,
        maxSelectedCount,
    } = props;
    const { t } = useTranslation();

    const presenter = useMemo(
        () =>
            new DialogViewManagerPresenter({
                onApply: props.onApply,
                onClose,
            }),
        [],
    );
    const { isSearchEnabled, canApply, init, unmount, onApply } = presenter;
    const [ref, setRef] = useState<HTMLDivElement>();

    useEffect(() => {
        if (isOpen && ref && viewId) {
            init({
                viewName: viewId,
                values,
                multiple,
                maxSelectedCount,
                scrollableTarget: ref,
                variables,
            });
        } else {
            unmount();
        }
    }, [isOpen, ref, values, maxSelectedCount, multiple]);

    const actions: IActionButtonProps[] = useMemo(() => {
        return [
            {
                title: t("common:common.cancel"),
                onClick: () => handleCancel(),
                buttonVariant: "outlined",
                color: "secondary",
                fullWidth: false,
                size: "small",
                uiTestId: "cancelButton",
            },
            {
                title: t("common:common.ready"),
                onClick: () => handleApply(),
                buttonVariant: "contained",
                disabled: !canApply,
                color: "primary",
                fullWidth: false,
                size: "small",
                uiTestId: "applyButton",
            },
        ];
    }, [canApply]);

    const handleApply = useCallback(() => {
        logEvent({
            component: AnalyticsEventComponent.Apply,
            action: AnalyticsEventAction.Click,
        });
        onApply();
    }, []);

    const handleCancel = useCallback(() => {
        logEvent({
            component: AnalyticsEventComponent.Cancel,
            action: AnalyticsEventAction.Click,
        });
        onClose();
    }, []);

    const handleClose = useCallback(() => {
        logEvent({
            component: AnalyticsEventComponent.Close,
            action: AnalyticsEventAction.Click,
        });
        onClose();
    }, []);

    const logEvent = (
        data: Omit<IAnalyticsEvent, "type" | "location">,
    ): void => {
        AnalyticsService.logEvent({
            type: AnalyticsEventType.Module,
            location: AnalyticsEventLocation.DialogViewManager,
            ...data,
        });
    };

    return (
        <UniversalDialog
            isOpen={isOpen}
            title={title}
            actions={actions}
            onClose={handleClose}
            dialogProps={{ maxWidth: "md" }}
            dialogContentProps={{ ref: setRef }}
        >
            <Box>
                {isSearchEnabled && (
                    <>
                        <ViewSearch
                            searchValue={presenter.searchValue}
                            autoFocus
                            onChange={presenter.onChangeSearchValue}
                            sx={{ pr: 1.25, pl: 2 }}
                        />
                        <Divider />
                    </>
                )}
                {presenter.renderView}
            </Box>
        </UniversalDialog>
    );
});
